export default {
  namespaced: true,
  state: {
    listHskkLevel: [],
    labels: [],
  },
  getters: {},
  actions: {},
  mutations: {
    setLabels(state, payload) {
      state.labels = payload;
    },
    setListHskkLevel(state, payload) {
      state.listHskkLevel = payload;
    },

  },
}